var pageHeight;

$(document).ready( function() {
  
  pageHeight = $(window).height();
  
  // Window scroll
  $(window).scroll( function() {
    if ($(document).scrollTop() > 120) {
      $('body').addClass('scrolled');
      
      if ($(document).scrollTop() > pageHeight) {
        $('body').addClass('scrolled-page');
      } else {
        $('body').removeClass('scrolled-page');
      }
    } else {
      $('body').removeClass('scrolled');
    }		
  });
	  
  // Scroll to top
  $('.back-to-top').click( function() {
    $('html,body').animate({ 
      scrollTop: 0
    }, 1000);
  });
  
  // Content area toggles
  $('.content--toggle').click( function() {
    var show_id = $(this).attr('data-show');
    var hide_id = $(this).attr('data-hide');
    
    $('#' + show_id).show();
    $('#' + hide_id).hide();
  });
  
  // Global menu
  $('.global-menu--menu a[data-target]').click( function(e) {
    e.preventDefault();
    
    // Hide open sub menues
    $('.global-menu--menu .sub-menu.active').removeClass('active');
    $('.global-menu--menu .primary-menu > .active').removeClass('active');
    
    var target = $(this).attr('data-target');
    
    // Show the target and close button
    $(target).addClass('active');
    $('.global-menu--menu .primary-menu .icon-close').addClass('active');
    
    // Highlight the current menu item
    $(this).parent().parent().addClass('active');
  });
  
  $('.global-menu--menu .primary-menu .icon-close').click( function() {
    $(this).removeClass('active');
    
    $('.global-menu--menu .sub-menu.active').removeClass('active');
    $('.global-menu--menu .primary-menu > .active').removeClass('active');
  });
  
  // Breadcrumb
  if ($('.breadcrumb').length) {
    $('.breadcrumb-primary').click( function() {
      if ($('.breadcrumb').hasClass('active')) {
        hideBreadcrumb();
      } else {
        showBreadcrumb();
      }
    });
  }
    
  // Copy viewer
  $('.global-action--copy-profile-url').click( function(e) {
    e.preventDefault();
    $('#ModalContent').html($('#CopyProfileUrlViewer').html());
    
    hideMenus();
    showModal();
    $('#ModalContent input[name="copy_url"]').select();
    document.execCommand("copy");
  }); 
    
  // Share viewer
  $('.global-action--share-profile-url').click( function(e) {
    e.preventDefault();
    $('#ModalContent').html($('#ShareProfileUrlViewer').html());
    
    setupShareLinks();
    hideMenus();
    showModal();
  });  
    
  // Overflowable content 
  $('.overflowable .more').click( function() {
    $(this).parent().addClass('open');
  });
  
  $('.overflowable .less').click( function() {
    $(this).parent().removeClass('open');
  });
    
  // Close buttons for floating menus at mobile sizes  
  $('.navmenu .close').click( function() {
    $(this).parentsUntil('.navmenu').parent().removeClass('active');
  });
  
  // Prevent menus from closing when you click in their content area
  $('.menu--floating .content').on('click', function(e) {
    e.stopPropagation();
  }); 
  
  // Control the click event for any element with a data-menu attribute
  $('[data-menu]').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();

    // If this is the active menu just close it
    if ($(this).hasClass('active')) {    
      
      $('.navmenu.active,[data-menu].active,.menu.active').removeClass('active');
      
      // Release the body
      setTimeout(function() {
        $('body').removeClass('menu-open');
      }, 500);
      
    // If this isn't already active then open it
    } else {
      
      // Close any open menus
      $('.navmenu.active,[data-menu].active,.menu.active').removeClass('active');
      
      // Fix the body
      $('body').addClass('menu-open');
      
      // Add active classes
      var target = $(this).attr('data-menu');
      $(this).addClass('active');
      $(target).addClass('active');
      
      // Do we need to load the content
      if ($('.loading:visible',target).length) {        
        $.ajax({
          url: $(this).attr('data-load'),
          type: 'GET',
          success: function(response) {
            var menu_content = response;
            
            // Add the menu content
            $('.loading',target).after(menu_content);
            
            // Hide the loading graphic
            $('.loading',target).hide();
          },
          error: function() {
            console.log('Menu error');
          }
        });
      } 

      // Remove the counter if one exists
      if ($('.count', this).length) {
        $('.count', this).hide();
      }

      setTimeout(bindMenuClose, 500);  
      
    }
      
  });
  
  // Trigger the click away events after the menu handle has been clicked
  function bindMenuClose() {
    $(document).bind('click', function(e) {
      if (!$(e.target).parentsUntil('menu').parent().hasClass('menu')) {
        $('.navmenu.active,[data-menu].active,.menu.active').removeClass('active');
        
        // Release the body
        setTimeout(function() {
          $('body').removeClass('menu-open');
        }, 500);
        
        $(document).unbind('click');
      }
    });        
  }
  
  // Menu close
  $('.menu-close').click( function() {
    $(this).parent().removeClass('active');
    $(this).parentsUntil('.menu').parent().removeClass('active');
    
    // Release the body
    setTimeout(function() {
      $('body').removeClass('menu-open');
    }, 500);
  });
  
  // jQuery UI accordion
  $('.accordion').each( function() {
    var active_index = 0;
    
    // If there is an "active" entry within this accordion open up the relevant section
    if ($('.active', this).length) {
      var heading = $('.active', this).parentsUntil('.accordion').parent().prev('h3');
      active_index = $('h3', this).index(heading);
    }
    
    // Initiate the accordion
    $(this).accordion({
      active: active_index,
      heightStyle: "content"
    });
  });
  
  // Check in description toggle
  $('.checkin .view-link').click( function() {
    $(this).parent().parent().prev('.checkin-description').addClass('active');
    $(this).siblings('.hide-link').css('display', 'inline-block').show();
    $(this).hide();
  });
  $('.checkin .hide-link').click( function() {
    $(this).parent().parent().prev('.checkin-description').removeClass('active');
    $(this).siblings('.view-link').show();
    $(this).hide();
  });
    
  // Add as favourite
  $('.add-as-favourite').click( function(e) {
    e.preventDefault();
    var data = [];
    
    $.ajax({
      url: $(this).attr('href'),
      type: 'GET',
      dataType: 'json',
      data: data,
      beforeSend: function() {        
      },
      success: function(response) {
        alert(response.message);
      },
      error: function() {
        console.log('Request error');
      }
    });
  });
  
  // Modal triggers for profile page and others
  setupModalTriggers();
  
  // Links to show inline content 
  $('[data-content-show]').click( function(e) {
    e.preventDefault();
    var target = $(this).attr('data-content-show');
    $(target).show();
  });
  $('[data-content-hide]').click( function(e) {
    e.preventDefault();
    var target = $(this).attr('data-content-hide');
    $(target).hide();
  });
  
  // Onload scrollTo targets
  $('[data-scroll-onload=true]:last').each( function() {
    if ($(this).attr('id') != '' && $(this).attr('id') !== undefined) {
      $('body').scrollTo('#'+$(this).attr('id'), 400);
    }    
  });
  
  // Chcek-in bar submission via AJAX
  $('#CheckinBar form').submit( function(e) {
    e.preventDefault();
    
    var data = $('#CheckinBar input, #CheckinBar select').serialize();
    data += '&modal=true';
    
    $.ajax({
      url: $(this).attr('action'),
      type: 'POST',
      data: data,
      dataType: 'json',
      success: function(response) {
        $('#CheckinBar .checkin--options').html('<p class="success">' + response.message + '</p>');
        $('#CheckinBar .checkin--submit input').remove();
      }
    });
  });
  
  // Buttons for triggering hidden file uploads in photo uploader
  $('.trigger-prev').click( function() {
    $(this).prev().click();
  })
  
  var menuUpSpeed = 200;
  var menuDownSpeed = 400
  
  // Activity menus
  $('.box.menu .heading').click( function() {
    // Close the current menu if it started open
    if ($(this).parent().hasClass('menu--open')) {
      
      $(this).next('.content').slideUp(menuUpSpeed, function() {
        $(this).parent().removeClass('menu--open');
      });      
      
    // Close the current menu
    } else if ($(this).parent().hasClass('menu--active')) {
      
      $(this).next('.content').slideUp(menuUpSpeed, function() {
        $(this).parent().removeClass('menu--active');
      });      
      
    // Close other menus and open this one
    } else {
      
      $(this).parent().addClass('menu--opening');
      
      if ($('.box.menu--active').length) {
        $('.box.menu--active .content').slideUp(menuUpSpeed, function() {
          $('.box.menu--active').not('.menu--opening').removeClass('menu--active');   
        });      
      } else if ($('.box.menu--open').length) {            
        $('.box.menu--open .content').slideUp(menuUpSpeed, function() {
          $('.box.menu--open').not('.menu--opening').removeClass('menu--open');   
        });      
      }
      
      $(this).parent().addClass('menu--active');
      $(this).next('.content').slideDown(menuDownSpeed, function() {
        $('.menu--opening').removeClass('menu--opening');
      });  
      
    }
  });
  
  // Viewer toggles  
  $('.viewer-section-title[data-toggling="true"]').click( function() {
    var parent = $(this).parent();
        
    if ($(parent).hasClass('open')) {
      $(parent).find('.viewer-section-content').slideUp(200, function() {
        $(this).parent().removeClass('open');
      });    
    } else {
      $(this).parent().addClass('open').removeClass('closed');
      $(parent).find('.viewer-section-content').slideDown(500, function() {
      });    
    }
  });
  
  // Membership toggles  
  $('.membership-accordion .membership-title').click( function() {
    var parent = $(this).parent();
    
    if ($(parent).hasClass('open')) {
      $(parent).find('.membership-content').slideUp(200, function() {
        $(this).parent().removeClass('open');
      });    
    } else {
      $(this).parent().addClass('open').removeClass('closed');
      $(parent).find('.membership-content').slideDown(500, function() {
      });    
    }
  });
  
  // We are going to start all sections as closed for mobile, so open them up if the viewport is wide enough
  if ($(window).width() > 600) {
    $('.viewer-section.start-open').not('.viewer-private').addClass('open');
  }
    
});

// Maintain maxlength for textareas
$(function() {  
  $("textarea[maxlength],input[maxlength]").bind('input propertychange', function() {  
    var maxLength = $(this).attr('maxlength');  
    var contentLength = $(this).val().length;
    var counter = $(this).attr('maxlength_counter');
    
    // Limit the content
    if (contentLength > maxLength) {  
      $(this).val($(this).val().substring(0, maxLength));  
    }  
    
    // Update the counter
    if ($(counter).length > 0) {
      $(counter).text(maxLength - contentLength);
    }
  });  
});

// Confirmation message when a user changes form data and then tries to leave the page
var needToConfirm = false;
                 
var myEvent = window.attachEvent || window.addEventListener;
var chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compitable

myEvent(chkevent, function(e) { // For >=IE7, Chrome, Firefox
  if (needToConfirm) {
    var confirmationMessage = "The changes you've made may not be saved."; 
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  }
  
  // If a profile modal is open, close the modal but don't navigate away
//  if ($('#ModalContent').is(':visible')) {    
//    hideModal();
//    var confirmationMessage = "Using the back button will take you away from your search results."; 
//    (e || window.event).returnValue = confirmationMessage;
//    return confirmationMessage;
//  }
  
});

// Show / hide the modal window
function showModal() {
  $('#ModalShadow').show().addClass('open');
  $('#ModalContent').show().addClass('open');
  $('html').addClass('modal-open');
  
  $('#ModalContent .close').click(hideModal);
  $('#ModalContent').click( function(event) {
    if (event.target === this) {
      hideModal();
    }
  });
}

function hideModal() {
  $('#ModalShadow').hide().removeClass();
  $('#ModalContent').html('').removeClass().hide();
  $('html').removeClass('modal-open');
}

// Functions for shortlist
function addFavourite(id) {
  $.ajax({
    url: '/favourites/add',
    type: 'GET',
    dataType: 'json',
    data: {id: id},
    beforeSend: function() {
      //
    },
    success: function(response) {
      $('.favourite-toggle .add').hide();
      $('.view-actions--favourite-add, .viewer-actions--save, .viewer-intro--favourite-add').addClass('u-hidden');
      
      $('.favourite-toggle .remove').show();
      $('.view-actions--favourite-remove, .viewer-actions--unsave, .viewer-intro--favourite-remove').removeClass('u-hidden');
            
      // Add a global message
      var notification_message = 'This user has been saved to your favourites';
      if (response.username !== '') {
        var notification_message = response.username + ' has been saved to your favourites';
      }
      addGlobalNotification('notification-global--success', notification_message);
    }
  });
}

function removeFavourite(id) {
  $.ajax({
    url: '/favourites/remove',
    type: 'GET',
    dataType: 'json',
    data: {id: id},
    beforeSend: function() {
      //
    },
    success: function(response) {
      $('.favourite-toggle .add').show();
      $('.view-actions--favourite-add, .viewer-actions--save, .viewer-intro--favourite-add').removeClass('u-hidden');
      
      $('.favourite-toggle .remove').hide();
      $('.view-actions--favourite-remove, .viewer-actions--unsave, .viewer-intro--favourite-remove').addClass('u-hidden');
    }
  });
}

function updateFavouriteComment(id, comment) {
  if (id != '' && comment != '') {
    var data = {
      id: id,
      comment: comment
    };
    
    $.ajax({
      url: '/favourites/' + id + '/updateComment',
      type: 'GET',
      dataType: 'json',
      data: data,
      beforeSend: function() {
        //
      },
      success: function(response) {
        //
      }
    });
  }
}

  
// Info tabs
function setupTabs() { 
  $('.tab').click( function() {
    var set = $(this).attr('data-tab-set');    
    var tab_id = $(this).attr('id');
    var page_id = tab_id.substr(4);
    
    $(this).siblings().removeClass('active');
    $('.' + set + '.info-page.active').removeClass('active');
    
    $(this).addClass('active');
    $('.' + set + '.info-page.info-' + page_id).addClass('active');
    
    var headerOffset = $('.profile--modal-menu').height();
//    headerOffset += 100;
//    headerOffset = 0;
    
    $('#ModalContent').scrollTo(
      '.' + set + '.info-page.info-' + page_id, 
      800, 
      { 
        offset: (headerOffset * -1)
      }
    );
  });
  
  // Load the first tab
//  $('.info-tabs .tab').first().click();
}
  
// Modal action for profile links
function setupModalTriggers() {
  $('a.modal-trigger').click( function(e) {
    e.preventDefault();
    
    var modal_href = $(this).attr('data-modal-href');
    var modal_class = $(this).attr('data-modal-class');
    var modal_action = $(this).attr('data-modal-action');
    var modal_scroll = $(this).attr('data-modal-scroll');
    
    var el = this;
    var original_text = $(this).text();
    
    $.ajax({
      url: modal_href,
      type: 'GET',
      dataType: 'html',
      beforeSend: function() {
        if ($(el).hasClass('btn')) {
          $(el).text('loading...');
        }
      },
      success: function(response) {
        $('#ModalContent').html(response).addClass(modal_class);
//        $('#ModalContent').append('<button class="close">CLOSE</button>');
        showModal();
        setupTabs();
        
        if (modal_action == 'profile') {
          $('#ModalContent').animate({
            scrollTop: '0px'
          }, 0);
        }
        
        if (modal_scroll != '') {
          $('#ModalContent').scrollTo(modal_scroll, 800);
        }
        
        if ($(el).hasClass('btn')) {
          $(el).text(original_text);
        }
        
        // Google Analytics event tracking
        if (typeof ga === "function") {
          
          // Parse the URL parts
          var url_parts = modal_href.split('/');
          
          var event_label = url_parts[4];
          
          var event_action = url_parts[3].toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
          });

          // Send the event to Google Analytics
          ga('send', {
            hitType: 'event',
            eventCategory: 'Popup',
            eventAction: event_action,
            eventLabel: event_label
          });
          
        }
        
        // Push this URL to the history to "fake out" the browser back button (because some users are apparently idiots)
//        var stateObj = {};
//        history.pushState(stateObj, 'Profile View', modal_href);
        
      },
      error: function() {
//        console.log('modal trigger error');
      }
    })
  });
}

function addGlobalNotification (classes, message) {
  var id = Math.floor((Math.random() * 1000) + 1);
  var message = '<div class="notification-global ' + classes + '" id="notification-' + id + '"><p>' + message + '</p></div>';
  
  // Add the message to the page
  $('body').prepend(message);
  
  // Fade after a timeout
  setTimeout(function() {
    $('#notification-' + id).fadeOut(500, function() {
      $(this).remove();
    });
  }, 1500);
}

// Breadcrumb functions
function showBreadcrumb() {
  $('.breadcrumb').addClass('active');
  
  // Bind the breadcrumb close click    
  $('.breadcrumb-secondary .close').click( function() {
    hideBreadcrumb();
  });
  
  // Bind a body click
  $('main > .centered').bind('click', function() {
    hideBreadcrumb();
  });
}

function hideBreadcrumb() {
  $('.breadcrumb').removeClass('active');
  
  // Unbind the body click
  $('main > .centered').unbind('click');
}

// Menu functions
function hideMenus() {
  $('.menu.active').removeClass('active');
  $('body').removeClass('menu-open');
}

  
// We need a function to setup all the correct sharing links
// This is kept separate from doc.ready to allow for use in other modal windows
function setupShareLinks() {
  
  // Force share URLs into new window
  $('.viewer-modal--sharing a').click( function(e) {
    e.preventDefault();    
    var url = $(this).attr('href');
    
    window.open(url, 'shareWindow', 'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    return false;
  });
}

// Override the default Google outbound tracking link
var trackOutboundLink = function(url, isExternal) {
    var params = {};

    if (!isExternal) {
        params.hitCallback = function () {
            document.location = url;
        }
    }
    ga('send', 'event', 'outbound', 'click', url, params);

    return isExternal;
}